<template>
  <div class="kyc">
    <div class="content-header cols flex-cell--static">
      <div>
        <h2>{{ $tfo('know_your_customer') }}</h2>
        <h3 class="header-detail"></h3>
      </div>
    </div>

    <el-alert
      v-if="!$store.getters.services.clusters"
      class="flex-cell--static mar-v-1"
      :title="$tf('clusters_disabled_warn')"
      type="warning"
      show-icon
      :closable="false"
    >
    </el-alert>

    <div class="kyc-chart-container" :class="dynamicClasses">
      <component
        :is="item.name === 'camera' ? 'bar-chart' : 'area-chart'"
        :key="`item-${item.name}-${state.selectedId === key}`"
        :id="key"
        :data="item.data"
        :name="item.name"
        :opacity="item.opacity"
        :title="item.title || item.name"
        :loading="item.loading"
        v-for="(item, key) in filtredItems"
        :full="filtredItems.length === 1"
        :enabled="item.active"
        @toggle-view="toggleViewHandler"
        @enable="() => ((item.active = true), $store.dispatch('get_kyc_items'))"
      />
    </div>
  </div>
</template>

<script>
import areaChart from './areaChart';
import barChart from './barChart';

export default {
  components: {
    areaChart,
    barChart
  },
  name: 'kyc',
  data() {
    return {};
  },
  computed: {
    dynamicClasses() {
      return { full: this.state.selectedId > -1 };
    },
    state() {
      return this.$store.state.kyc;
    },
    filter() {
      return this.state.filter.current;
    },
    filtredItems() {
      return this.state.items.filter((v, i) => (this.state.selectedId > -1 ? this.state.selectedId === i : true));
    }
  },
  mounted() {
    window.addEventListener('keydown', this.keyDownHandler);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keyDownHandler);
  },
  methods: {
    toggleViewHandler(id) {
      this.state.selectedId = this.state.selectedId === -1 ? id : -1;
      this.$nextTick(() => window.dispatchEvent(new Event('resize')));
    },
    keyDownHandler(e) {
      if (e.keyCode === 27 && this.state.selectedId > -1) {
        this.state.selectedId = -1;
        e.preventDefault();
      }
      if (e.keyCode === 37 && this.state.selectedId > -1) {
        this.state.selectedId -= 1;
        if (this.state.selectedId < 0) this.state.selectedId = 0;
        e.preventDefault();
      }
      if (e.keyCode === 39 && this.state.selectedId > -1) {
        this.state.selectedId += 1;
        if (this.state.selectedId > 4) this.state.selectedId = 4;
        e.preventDefault();
      }
    }
  }
};
</script>

<style lang="stylus">
.kyc {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .kyc-chart-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, minmax(150px, 200px));
    grid-gap: 1rem;
    flex: 1 1;
  }

  .kyc-chart-container.full {
    display: block;
  }

  .kyc-chart-item {
    position: relative;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 1rem;
    display: block;
    height: 100%;
    width: 100%;
    min-width: 240px;
    overflow: hidden;

    &--disabled {
      background-color: rgba(255, 255, 255, 0.4);
    }

    .item--header {
      z-index: 10;
      position: absolute;
      left: 0;
      right: 0;
      padding: 0.75rem;
    }

    .item--title {
      flex: 1 1;
    }

    .item--buttons {
      display: flex;
    }

    .item--text-huge {
      font-size: 2rem;
      color: #fff;
    }

    .item--text-description {
      text-align: center;
      font-size: 11px;
      margin-top: -0.5rem;
    }
  }
}
</style>
